import handleNavToServeBeta from '../../store/utils/navToServeBeta';

const DeliveryReports = ({ reportType }) => {
  const params = new URLSearchParams(window.location.search);
  const venueId = params.get('venueId');
  const gte = params.get('gte');
  const lte = params.get('lte');

  if (reportType === 'daily') {
    handleNavToServeBeta({ path: `/reports/daily`, manualVenueId: venueId || null, gte });
  } else if (reportType === 'weekly') {
    handleNavToServeBeta({
      path: `/reports/weekly`,
      manualVenueId: venueId || null,
      gte,
      lte,
    });
  }

  return null;
};

export default DeliveryReports;
