import React from 'react';
import { Container, Card, CardContent, Typography, Grid, Button, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { getCognitoSignInUri } from '../../cognito/utils';
import { clearAuthenticationTokens } from '../../cognito/sessionTokens';
import cySelectors from '../../tests/cySelectors';

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  login: {
    width: '60%',
    [theme.breakpoints.down('md')]: {
      width: '100%',
    },
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  border: {
    [theme.breakpoints.up('sm')]: {
      borderRight: '1px solid lightgrey',
    },
    [theme.breakpoints.down('sm')]: {
      borderBottom: '1px solid lightgrey',
      marginBottom: theme.spacing(2),
      paddingBottom: theme.spacing(2),
    },
  },
  title: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(1),
    whiteSpace: 'pre-line',
  },
  subtitle: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  titleBox: {
    minHeight: 100,
  },
}));

const Login = () => {
  const classes = useStyles();
  clearAuthenticationTokens();
  return (
    <Container className={classes.root}>
      <Card className={classes.login} data-cy={cySelectors.NOTAUTHENTICATED}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} sm={6} className={classes.border}>
              <Box className={classes.titleBox}>
                <Typography className={classes.title} variant="h6" align="center">
                  Registered User
                </Typography>
                <Typography className={classes.subtitle} variant="subtitle1" align="center">
                  Have an account? Sign in now
                </Typography>
              </Box>
              <Box m={2} display="flex" alignItems="flex-end" justifyContent="center">
                <Button
                  color="primary"
                  align="center"
                  size="small"
                  variant="contained"
                  onClick={() => {
                    clearAuthenticationTokens();
                  }}
                  href={getCognitoSignInUri()}
                >
                  Log in
                </Button>
              </Box>
            </Grid>

            <Grid item xs={12} sm={6}>
              <Box className={classes.titleBox}>
                <Typography className={classes.title} variant="h6" align="center">
                  New Customer
                </Typography>
                <Typography className={classes.subtitle} variant="subtitle1" align="center">
                  New to Sessions Serve?
                </Typography>
              </Box>
              <Box m={2} display="flex" alignItems="flex-end" justifyContent="center">
                <Button
                  color="primary"
                  align="center"
                  size="small"
                  variant="contained"
                  underline="always"
                  href="mailto:support@sessions.co.uk"
                >
                  Message us!
                </Button>
              </Box>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </Container>
  );
};

export default Login;
