import { Box, Card, Grid, TextField, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ESPicker from '../../components/ESPicker';
import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import ReportsHeader from '../../components/ReportsHeader';
import ReportsPlatformBreakdown from '../../components/ReportsPlatformBreakdown';
import useESPicker from '../../hooks/useESPicker';
import deliveryPlatforms from '../../shared/constants/deliveryPlatforms';
import { rangeEnums } from '../../shared/utils/dateRanges';
import toUTC from '../../shared/utils/toUTC';
import { fetchDeliveryMetrics } from '../../store/reports';
import { getDeliveryMetricsState } from '../../store/reports/selectors';

const descriptions = {
  avgRatings: `Average of customers’ ratings (out of 5) after receiving their delivery orders`,
  avgOpenRate: `Proportion of your scheduled delivery opening hours that you were open`,
  missingItems: `Share of orders that had one or more items missing`,
  avgPrepTime: `Average time between receiving the order and handing it off to a rider`,
  avgRiderWaitTime: `The share of orders where the rider had to wait for more than 5 minutes past
   the scheduled pickup time`,
  percentRejectedOrders: `The share of orders which were rejected`,
};

const useStyles = makeStyles((theme) => ({
  chart: {
    padding: theme.spacing(2),
    height: '100%',
  },
  topCards: {
    marginBottom: theme.spacing(2),
  },
  card: {
    marginTop: theme.spacing(1),
  },
}));

const ReportContents = ({ brands }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [activePlatforms, setActivePlatforms] = useState(deliveryPlatforms);
  const filteredBrands = brands?.filter((brand) => brand.active);
  const [activeBrands, setActiveBrands] = useState(filteredBrands);
  const deliveryMetricsState = useSelector(getDeliveryMetricsState);
  const { loading, data, error } = deliveryMetricsState;
  const memoizedActivePlatforms = useMemo(
    () => activePlatforms?.map((platform) => platform.key),
    [activePlatforms],
  );
  const memoizedActiveBrands = useMemo(
    () => activeBrands?.map((brand) => brand.brandPrefix),
    [activeBrands],
  );

  const esPicker = useESPicker({
    useOrder: false,
    useSearch: false,
    useUnits: false,
    useReportingType: false,
    useBrands: true,
    usePlatforms: true,
  });
  const { selectedDates, dateRange, units, reportingType } = esPicker;

  const fetchData = useCallback(() => {
    dispatch(
      fetchDeliveryMetrics({
        dateRange,
        from: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.from),
        to: dateRange === rangeEnums.CUSTOM && toUTC(selectedDates.to),
        unit: units,
        reportingType,
        platforms: memoizedActivePlatforms,
        brands: memoizedActiveBrands,
      }),
    );
  }, [
    dispatch,
    dateRange,
    selectedDates.from,
    selectedDates.to,
    units,
    reportingType,
    memoizedActivePlatforms,
    memoizedActiveBrands,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const handleActivePlatformsChange = (_event, value) => {
    if (value.length >= 1) {
      setActivePlatforms(value);
    }
  };

  const handleActiveBrandsChange = (_event, value) => {
    if (value.length >= 1) {
      setActiveBrands(value);
    }
  };

  return (
    <>
      <PageHeader fullWidth>
        <Box display="flex" justifyContent="space-between" flexDirection="column">
          <Typography variant="h2" component="h1">
            Delivery Metrics
          </Typography>
          <Typography variant="body1" component="p">
            See how you are performing on metrics specific to delivery. These metrics are critical
            in maintaining high sales on Deliveroo, UberEats, and JustEat.
          </Typography>
          <ESPicker esPicker={esPicker} left />
          {brands && deliveryPlatforms && (
            <Grid container spacing={2} className={classes.topCards}>
              {activeBrands && (
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    multiple
                    limitTags={3}
                    options={filteredBrands}
                    id="brands-multiselect"
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Brands"
                        value={activeBrands}
                      />
                    )}
                    disableClearable
                    disableCloseOnSelect
                    disableListWrap
                    getOptionLabel={(option) => option.brandName}
                    value={activeBrands}
                    onChange={handleActiveBrandsChange}
                  />
                </Grid>
              )}
              {activePlatforms && (
                <Grid item xs={12} md={4}>
                  <Autocomplete
                    multiple
                    limitTags={3}
                    id="delivery-platforms"
                    options={deliveryPlatforms}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Delivery platforms"
                        value={activePlatforms}
                      />
                    )}
                    disableClearable
                    disableCloseOnSelect
                    disableListWrap
                    getOptionLabel={(option) => option.name}
                    value={activePlatforms}
                    onChange={handleActivePlatformsChange}
                  />
                </Grid>
              )}
            </Grid>
          )}
        </Box>
      </PageHeader>
      <Page loading={loading} fullWidth>
        {error && (
          <Box display="flex" justifyContent="center" alignItems="center" height="100%">
            <Typography variant="h2" component="p">
              Error: {error?.response?.data?.message || 'An unexpected error occurred'}
            </Typography>
          </Box>
        )}
        {data && (
          <>
            <Grid container spacing={2} className={classes.topCards}>
              <Grid item xs={12} md={4} className={classes.card}>
                <Card className={classes.chart}>
                  <ReportsHeader
                    title="Average New Ratings"
                    description={descriptions.avgRatings}
                    decimalPlaces={2}
                    data={{
                      current: data?.current.avgRatings,
                      previous: data?.previous.avgRatings,
                    }}
                  />
                  <ReportsPlatformBreakdown
                    data={data}
                    field="ratingByPlatform"
                    platforms={activePlatforms}
                  />
                </Card>
              </Grid>
              <Grid item xs={12} md={4} className={classes.card}>
                <Card className={classes.chart}>
                  <ReportsHeader
                    title="Open Rates"
                    description={descriptions.avgOpenRate}
                    decimalPlaces={2}
                    suffix="%"
                    data={{
                      current: data?.current.avgOpenRate,
                      previous: data?.previous.avgOpenRate,
                    }}
                  />
                  <ReportsPlatformBreakdown
                    data={data}
                    field="openRateByPlatform"
                    platforms={activePlatforms}
                    suffix="%"
                  />
                </Card>
              </Grid>
              <Grid item xs={12} md={4} className={classes.card}>
                <Card className={classes.chart}>
                  <ReportsHeader
                    title="Orders with Missing Items"
                    description={descriptions.missingItems}
                    decimalPlaces={2}
                    suffix="%"
                    data={{
                      current: data?.current.percentMissingItems,
                      previous: data?.previous.percentMissingItems,
                    }}
                    negativePercentIsPositive
                  />
                  <ReportsPlatformBreakdown
                    data={data}
                    field="percentMissingItemsByPlatform"
                    platforms={activePlatforms}
                    suffix="%"
                    negativePercentIsPositive
                  />
                </Card>
              </Grid>
            </Grid>
            {activePlatforms.map((platform) => platform.key).includes('ROO') && (
              <>
                <Typography variant="h2" component="h2">
                  Deliveroo Only Metrics
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={4} className={classes.card}>
                    <Card className={classes.chart}>
                      <ReportsHeader
                        title="Average Prep Time"
                        description={descriptions.avgPrepTime}
                        suffix=" mins"
                        data={{
                          current: data?.current.avgPrepTime,
                          previous: data?.previous.avgPrepTime,
                        }}
                        negativePercentIsPositive
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.card}>
                    <Card className={classes.chart}>
                      <ReportsHeader
                        title="Rider Wait Time Over 5 Minutes"
                        description={descriptions.avgRiderWaitTime}
                        decimalPlaces={2}
                        suffix="%"
                        data={{
                          current: data?.current.riderLongWait,
                          previous: data?.previous.riderLongWait,
                        }}
                        negativePercentIsPositive
                      />
                    </Card>
                  </Grid>
                  <Grid item xs={12} md={4} className={classes.card}>
                    <Card className={classes.chart}>
                      <ReportsHeader
                        title="Rejected Orders"
                        description={descriptions.percentRejectedOrders}
                        decimalPlaces={2}
                        suffix="%"
                        data={{
                          current: data?.current.percentRejectedOrders,
                          previous: data?.previous.percentRejectedOrders,
                        }}
                        negativePercentIsPositive
                      />
                    </Card>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </Page>
    </>
  );
};

export default ReportContents;
