import * as Yup from 'yup';

import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Formik, Form, Field } from 'formik';
import { RadioGroup, Select, TextField } from 'formik-mui';
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  MenuItem,
  Paper,
  Radio,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment from 'moment';
import BackArrow from '../../components/BackArrow';
import Page from '../../components/Page';
import withVenue from '../../hoc/withVenue';
import { getVenueMenusState } from '../../store/venueMenus/selectors';
import shouldLoad from '../../shared/utils/shouldLoad';
import { clearVenueMenus, fetchVenueMenus } from '../../store/venueMenus';
import menuTypes from '../../shared/constants/menuTypes';
import UniversalSave from '../../components/UniversalSave';
import { churnVenue, clearVenue } from '../../store/venues';
import { useNotifications } from '../../shared/contexts/Notifications/useNotifications';
import { getErrorMessage } from '../../shared/utils/errors';

const useStyles = makeStyles((theme) => ({
  formContainer: {
    padding: theme.spacing(3),
    marginTop: theme.spacing(2),
    borderRadius: 12,
  },
  formTitle: {
    ...(theme.customFonts?.mediumBold || { fontWeight: 600 }),
    marginBottom: theme.spacing(2),
    color: '#343860',
  },
  formControl: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  formLabel: {
    ...(theme.customFonts?.small || { fontSize: '0.875rem' }),
    color: '#343860',
    marginBottom: theme.spacing(1),
  },
  formControlLabel: {
    '& .MuiFormControlLabel-label': {
      ...(theme.customFonts?.medium || {}),
      color: '#343860',
    },
  },
  description: {
    ...(theme.customFonts?.medium || {}),
    color: '#5A7296',
    marginTop: theme.spacing(2),
  },
}));

const churnReasons = [
  'Trial Period',
  'Sales',
  'Margin/Profitability',
  'Operations',
  'Engagement',
  'Never Launched',
  'Swapping to New Brands',
  'Churn & Replace Zone (new licensing or franchise)',
  'Site lost/moving',
];

const ChurnSchema = Yup.object().shape({
  type: Yup.string().oneOf(['VENUE', 'MENU']).required(),
  decision: Yup.string().oneOf(['SESSIONS', 'PARTNER', 'SHARED']).required(),
  reason: Yup.string().oneOf(churnReasons).required('A reason must be selected'),
  menus: Yup.array()
    .of(Yup.string())
    .when('type', {
      is: 'MENU',
      then: (schema) => schema.min(1, 'At least one menu must be selected').required(),
      otherwise: (schema) => schema,
    }),
  churnAt: Yup.date().required('Churn date is required'),
  notes: Yup.string(),
});

const dateIsMondayInFuture = (date) =>
  date.toDate().getDay() === 1 && moment(date.toDate()).isAfter(moment());

const Churn = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const venueMenusState = useSelector(getVenueMenusState);

  const { loading: menusLoading, data: menus, error: menusError } = venueMenusState;

  useEffect(() => {
    if (shouldLoad(venueMenusState)) {
      dispatch(fetchVenueMenus());
    }
  }, [dispatch, venueMenusState]);

  const deliveryMenus = menus?.filter((menu) => menu.type === menuTypes.DELIVERY.value);

  const initialValues = {
    type: 'VENUE',
    decision: 'SESSIONS',
    reason: '',
    churnAt: null,
    notes: '',
    menus: [],
  };

  const handleSubmit = async (values) => {
    const valuesToSubmit = {
      ...values,
      churnAt: values.churnAt.format('YYYY-MM-DD'),
    };

    try {
      await dispatch(churnVenue({ values: valuesToSubmit }));
      showSuccessNotification('Churn was successful');
      dispatch(clearVenueMenus());
      dispatch(clearVenue());
      window.location.href = '/admin';
    } catch (e) {
      showErrorNotification(getErrorMessage(e));
    }
  };

  return (
    <Page title="Churn" loading={menusLoading} error={menusError}>
      <BackArrow to="/admin" text="Admin" />

      <Paper elevation={0} className={classes.formContainer}>
        <Typography variant="h6" className={classes.formTitle}>
          Churn
        </Typography>

        <Formik
          initialValues={initialValues}
          onSubmit={handleSubmit}
          validationSchema={ChurnSchema}
          validateOnChange
        >
          {({ values, isValid, errors, dirty, resetForm }) => (
            <>
              <UniversalSave
                isValid={isValid}
                errors={errors}
                dirty={dirty}
                onSave={() => handleSubmit(values)}
                onDiscard={() => {
                  resetForm({ values: initialValues });
                }}
              />
              <Form>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend" className={classes.formLabel}>
                    Select churn type
                  </FormLabel>
                  <Field component={RadioGroup} name="type" className={classes.radioGroup}>
                    <FormControlLabel
                      value="VENUE"
                      control={<Radio color="primary" />}
                      label="Venue"
                      className={classes.formControlLabel}
                    />
                    <FormControlLabel
                      value="MENU"
                      control={<Radio color="primary" />}
                      label="Menu"
                      className={classes.formControlLabel}
                    />
                  </Field>
                </FormControl>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend" className={classes.formLabel}>
                    Churn decision
                  </FormLabel>
                  <Field component={RadioGroup} name="decision" className={classes.radioGroup}>
                    <FormControlLabel
                      value="SESSIONS"
                      control={<Radio color="primary" />}
                      label="Sessions"
                      className={classes.formControlLabel}
                    />
                    <FormControlLabel
                      value="PARTNER"
                      control={<Radio color="primary" />}
                      label="Partner"
                      className={classes.formControlLabel}
                    />
                    <FormControlLabel
                      value="SHARED"
                      control={<Radio color="primary" />}
                      label="Shared"
                      className={classes.formControlLabel}
                    />
                  </Field>
                </FormControl>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend" className={classes.formLabel}>
                    Churn reason
                  </FormLabel>
                  <Field component={Select} name="reason" className={classes.formControl}>
                    <MenuItem value="" disabled>
                      Select a reason
                    </MenuItem>
                    {churnReasons.map((option) => (
                      <MenuItem key={option} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>

                {!!deliveryMenus && values.type === 'MENU' && (
                  <FormControl component="fieldset" className={classes.formControl}>
                    <FormLabel component="legend" className={classes.formLabel}>
                      Menus to churn
                    </FormLabel>
                    <Field
                      component={Select}
                      name="menus"
                      className={classes.formControl}
                      multiple
                      renderValue={(selected) => {
                        if (!selected.length) return '';

                        return selected
                          .map((menuName) => {
                            const menuItem = deliveryMenus.find(
                              (menu) => menu.menuName === menuName,
                            );
                            return menuItem
                              ? `${menuItem.brandName} - ${menuItem.brandMenuVariationName}`
                              : menuName;
                          })
                          .join(', ');
                      }}
                    >
                      {deliveryMenus.map((menu) => (
                        <MenuItem
                          key={menu.menuName}
                          value={menu.menuName}
                          disabled={menu.isChurning}
                        >
                          {menu.brandName} - {menu.brandMenuVariationName}{' '}
                          {menu.isChurning && '(Churn pending)'}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                )}

                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend" className={classes.formLabel}>
                    Churn date
                  </FormLabel>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Field name="churnAt">
                      {({ field, form }) => (
                        <DatePicker
                          value={field.value}
                          onChange={(date) => form.setFieldValue('churnAt', date)}
                          shouldDisableDate={(date) => !dateIsMondayInFuture(date)}
                          format="DD/MM/YYYY"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              fullWidth
                              variant="outlined"
                              error={form.errors.churnAt && form.touched.churnAt}
                              helperText={form.touched.churnAt && form.errors.churnAt}
                            />
                          )}
                        />
                      )}
                    </Field>
                  </LocalizationProvider>
                </FormControl>

                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend" className={classes.formLabel}>
                    Notes
                  </FormLabel>
                  <Field
                    component={TextField}
                    name="notes"
                    multiline
                    rows={4}
                    fullWidth
                    variant="outlined"
                  />
                </FormControl>
              </Form>
            </>
          )}
        </Formik>
      </Paper>
    </Page>
  );
};

export default withVenue(Churn, null, [clearVenueMenus, clearVenue]);
