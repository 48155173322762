import { postBare } from '../../api/httpRequest';

const handleNavToServeBeta = async ({ path, manualVenueId, gte, lte }) => {
  const venueId = localStorage.getItem('venue_id');
  const refreshToken = localStorage.getItem('refresh_token');

  const data = {
    venueId: manualVenueId || venueId,
    gte,
    lte,
    refreshToken,
    destination: `${process.env.REACT_APP_BETA_URI}${path}`,
  };

  const response = await postBare('login/beta-dashboard', {
    data,
  });

  if (response.status === 200) {
    const { responseURL } = response.data;

    window.location.href = responseURL;
  }
};

export default handleNavToServeBeta;
