import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@mui/styles';
import { Box, Button, Grid, Typography } from '@mui/material';
import QRCode from 'qrcode.react';
import moment from 'moment-timezone';
import shouldLoad from '../../shared/utils/shouldLoad';

import Page from '../../components/Page';
import PageHeader from '../../components/PageHeader';
import withVenue from '../../hoc/withVenue';
import { getServiceStatusState } from '../../store/serviceStatus/selectors';
import { rangeEnums } from '../../shared/utils/dateRanges';
import MeetCard from './MeetCard';
import QuickActionsCard from './QuickActionsCard';
import ReportsCard from './ReportsCard';
// import ReferCard from './ReferCard';
import TasksCard from './TasksCard';
import OffersHighlightCard from './OffersHighlightCard';
import OffersCard from './OffersCard';
import { enrichStation } from '../../shared/utils/openingTimesUtils';
import { clearCampaigns, fetchCampaigns } from '../../store/campaigns';
import { getCampaignsState } from '../../store/campaigns/selectors';
import isMobile from '../../shared/utils/isMobile';
import { isBrandUp, isPro } from '../../store/venues/selectors';
import MenuWarning from '../Menus/MenuWarning';
import { clearServiceStatus, fetchServiceStatus } from '../../store/serviceStatus';
import { getReportOverviewState } from '../../store/reports/selectors';
import { clearReportOverview, fetchReportOverview } from '../../store/reports';
import { getIntroTasksState } from '../../store/introTasks/selectors';
import { clearTasks, fetchIntroTasks } from '../../store/introTasks';
import CustomDialog from '../../components/CustomDialog';
import useESPicker from '../../hooks/useESPicker';
import useHasChanged from '../../hooks/useHasChanged';
import useStripeStatusBanner from '../../hooks/useStripeStatusBanner';
import DeliveryReportsCard from './DeliveryReportsCard';

const useStyles = makeStyles(() => ({
  heading: {
    marginBottom: '5px',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontFamily: 'Sen',
    fontWeight: 'bold',
    flexGrow: 1,
    marginBottom: '1rem',
  },
  column: {
    padding: '2rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  subtitle: {
    fontFamily: 'Sen',
    fontWeight: 'bold',
    marginBottom: '1rem',
    marginTop: '1rem',
  },
}));

const Home = ({ venue }) => {
  const { venueId, venuePath, email } = venue;
  const isMultiVendor = venue?.children;
  const { isOpen } = enrichStation(venue);
  const classes = useStyles();
  const dispatch = useDispatch();
  const [qrDialogOpen, setQrDialogOpen] = useState(false);
  const isBrandup = useSelector(isBrandUp);
  const ispro = useSelector(isPro);

  const { loading: campaignsLoading, data: campaigns } = useSelector(getCampaignsState);
  const { loading: analyticsLoading, data: analytics } = useSelector(getReportOverviewState);
  const { loading: tasksLoading, data: tasksData } = useSelector(getIntroTasksState);
  const serviceStatusState = useSelector(getServiceStatusState);
  const { data: serviceStatus } = serviceStatusState;
  const { reportingType } = useESPicker();
  const hasVenueIdChanged = useHasChanged(venueId);

  const webappPath = venuePath && `${process.env.REACT_APP_WEBAPP_URI}/${venuePath}`;
  const isDelivery = venue.accountType === 'DELIVERY_ONLY' || venue.accountType === 'FRANCHISE';
  const { taskType, launchDate, removed } = tasksData || {};

  useEffect(() => {
    if (!analytics && !analyticsLoading) {
      dispatch(
        fetchReportOverview({
          dateRange: rangeEnums.TODAY,
          reportingType,
        }),
      );
    }

    if (!campaigns && !campaignsLoading) dispatch(fetchCampaigns());
  }, [analytics, analyticsLoading, campaigns, campaignsLoading, dispatch, reportingType]);

  const openExternalLink = (url) => {
    if (!isMobile) {
      setQrDialogOpen(true);
    } else {
      window.open(url, '_blank');
    }
  };

  const renderTasksCard = (venueType) => (
    <TasksCard
      openExternalLink={() => openExternalLink(webappPath)}
      tasksLoading={tasksLoading}
      tasksData={tasksData}
      venueType={venueType}
      launchDate={launchDate}
      email={email}
      removed={removed}
    />
  );

  const { banner } = useStripeStatusBanner(false);

  useEffect(() => {
    if (shouldLoad(serviceStatusState)) dispatch(fetchServiceStatus());
  }, [dispatch, serviceStatusState]);

  useEffect(() => {
    if (hasVenueIdChanged) {
      dispatch(fetchIntroTasks());
    }
  }, [hasVenueIdChanged, dispatch]);

  useEffect(() => {
    dispatch(fetchIntroTasks());
    return () => {
      dispatch(clearTasks());
    };
  }, [dispatch]);

  return (
    <>
      <PageHeader>
        <div className={classes.heading}>
          <Typography className={classes.title} variant="h2" component="h1">
            Home
          </Typography>
        </div>
      </PageHeader>
      <Page loading={campaignsLoading || analyticsLoading || tasksLoading}>
        <Typography className={classes.title} variant="subtitle2">
          {isOpen ? 'You are open for business' : 'Currently closed'}
        </Typography>
        {serviceStatus && <MenuWarning serviceStatus={serviceStatus} />}
        {banner && (
          <Grid item sx={{ mb: 3 }}>
            {banner}
          </Grid>
        )}
        <Grid container spacing={2}>
          <Grid xs={12} md={8} item>
            <Grid container spacing={2}>
              {analytics &&
                !isMultiVendor &&
                taskType === 'DUET' &&
                launchDate > moment().local().format('YYYY-MM-DD') &&
                renderTasksCard(taskType)}
              {!isDelivery &&
                analytics &&
                !isMultiVendor &&
                taskType !== 'DUET' &&
                renderTasksCard(taskType)}
              <ReportsCard />
              {isDelivery && <DeliveryReportsCard />}
              {ispro && <OffersCard campaigns={campaigns} />}
            </Grid>
          </Grid>
          <Grid item xs={12} md={4}>
            <Grid container spacing={2}>
              <QuickActionsCard type={taskType} />
              {ispro && <OffersHighlightCard campaigns={campaigns} />}
              {isBrandup && <MeetCard />}
              {/** <ReferCard /> * */}
              <Grid />
            </Grid>
          </Grid>
        </Grid>
      </Page>
      <CustomDialog isDialogOpen={qrDialogOpen} handleCloseDialog={() => setQrDialogOpen(false)}>
        <Box className={classes.column}>
          <QRCode value={webappPath} size={200} />
          <Typography variant="h4" className={classes.subtitle}>
            Scan your QR code with your phone to make an order
          </Typography>
          <Button variant="outlined" color="primary" onClick={() => setQrDialogOpen(false)}>
            Close
          </Button>
        </Box>
      </CustomDialog>
    </>
  );
};

export default withVenue(Home, null, [
  clearCampaigns,
  clearServiceStatus,
  clearReportOverview,
  clearTasks,
]);
