import moment from 'moment';
import { del, get, patch, post } from './httpRequest';
import { getSummaryAmount } from '../pages/Adjustments/Adjustment/AdjustmentForm';

export const fetchAdjustmentsApi = async () => {
  const response = await get(`/adjustments`);

  return response.data;
};

export const fetchAdjustmentApi = async (id) => {
  const response = await get(`/adjustments/${id}`);

  return response.data;
};

export const fetchAdjustmentLogApi = async (id) => {
  const response = await get(`/adjustments/${id}/logs`);

  return response.data;
};

export const createAdjustmentApi = async (data) => {
  const parsedAmount = getSummaryAmount({
    amount: data.amount,
    applyVat: data.vatIncluded,
  });

  const formData = { ...data, amount: parsedAmount };
  const formatStartDate = moment(formData.startDate, 'DD/MM/YYYY').toISOString(false);
  let interval;

  if (formData.schedule !== 'ONE_OFF') {
    interval = 'WEEKLY';
  }

  if (formData.description === '') delete formData.description;

  const response = await post(`/adjustments`, {
    data: {
      ...formData,
      amount: Number(formData.amount),
      startDate: formatStartDate,
      interval,
    },
  });

  return response.data;
};

export const patchAdjustmentApi = async (id, data) => {
  const parsedAmount = getSummaryAmount({
    amount: data.amount,
    applyVat: data.vatIncluded,
  });

  const formData = { ...data, amount: parsedAmount };
  const formatStartDate = moment(formData.startDate, 'DD/MM/YYYY').toISOString(false);
  let interval;

  if (formData.schedule !== 'ONE_OFF') {
    interval = 'WEEKLY';
  }

  if (formData.description === '' || formData.description === null) delete formData.description;

  const response = await patch(`/adjustments/${id}`, {
    data: {
      ...formData,
      amount: Number(formData.amount),
      startDate: formatStartDate,
      interval,
    },
  });

  return response.data;
};

export const deleteAdjustmentApi = async (id) => {
  const response = await del(`/adjustments/${id}`);

  return response.data;
};

export const deactivateAdjustmentApi = async (id) => {
  const response = await patch(`/adjustments/${id}/deactivate`);

  return response.data;
};
