import { Box, Button, Card, Typography, useMediaQuery, useTheme } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { makeStyles } from '@mui/styles';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import DeleteVenueManagerDialog from '../../../components/AdminForm/DeleteVenueManagerDialog';
import ResetPasswordDialog from '../../../components/AdminForm/ResetPasswordDialog';
import KebabMenu from '../../../components/KebabMenu';
import useRoles from '../../../hooks/useRoles';
import DISPLAYROLES from '../../../shared/constants/displayRoles';
import ROLES from '../../../shared/constants/roles';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import createTemporaryPassword from '../../../shared/utils/createTemporaryPassword';
import { getErrorMessage } from '../../../shared/utils/errors';
import { deleteUser, fetchAdmin, forcePasswordReset } from '../../../store/admin';
import {
  createManager,
  deleteManager,
  fetchManagers,
  updateManager,
} from '../../../store/managers';
import { getRolesFromToken, getUserName } from '../../../store/session/selectors';
import { fetchVenue } from '../../../store/venues';
import ManagerFormTabs from './ManagerFormDialog/index';

const useStyles = makeStyles((theme) => ({
  heading: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  tableTitle: {
    ...theme.customFonts.mediumBold,
    color: theme.customPalette.greyDarker,
    marginBottom: '8px',
  },
  tableheader: {
    '& td': {
      padding: '8px 16px 8px 16px',
    },
    '& td:first-child': {
      paddingLeft: '24px',
      paddingRight: 0,
    },
  },
  tablerow: {
    '& td': {
      padding: '24px 16px 24px 16px',
    },
    '& td:first-child': {
      paddingLeft: '16px',
      paddingRight: 0,
    },
  },
  columnTitle: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDark,
  },
  rowName: {
    ...theme.customFonts.smallBold,
    color: theme.customPalette.greyDarker,
  },
  role: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
  },
  userId: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDark,
  },

  newManagerCard: {
    padding: theme.spacing(2),
    marginTop: theme.spacing(2),
    borderRadius: '8px',
  },
  tabPanel: {
    paddingLeft: 0,
    paddingRight: 0,
  },
  tableCard: {
    borderRadius: '8px',
    marginBottom: '24px',
  },
  truncate: {
    width: '155px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
}));

const displayRole = (isUpCoSuper, isUpCoAdmin, role) => {
  if (isUpCoSuper) {
    return 'Sessions Super Admin';
  }
  if (isUpCoAdmin) {
    return 'Sessions Admin';
  }
  return role;
};

const ManagersTableTeam = ({ managers }) => {
  const { subtab } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { showErrorNotification, showSuccessNotification } = useNotifications();
  const classes = useStyles();
  const { isAdmin, isRoleLessthanMe } = useRoles();
  const [addManagerDialogOpen, setAddManagerDialogOpen] = useState(false);
  const [newManagerEmail, setNewManagerEmail] = useState('');
  const [newManagerCountryCode, setNewManagerCountryCode] = useState('+44');
  const [newManagerPhoneNumber, setNewManagerPhoneNumber] = useState('');
  const [newManagerName, setNewManagerName] = useState(undefined);
  const [newManagerReportsEmails, setNewManagerReportsEmails] = useState([]);
  const [newManagerRole, setNewManagerRole] = useState(ROLES.STAFF);
  const [enabledManagers, setEnabledManagers] = useState([]);
  const [disabledManagers, setDisabledManager] = useState([]);
  const [confirmPwdDialogOpen, setConfirmPwdDialogOpen] = useState(false);
  const [confirmManagerDeleteDialogOpen, setConfirmManagerDeleteDialogOpen] = useState(false);
  const [temporaryPassword, setTemporaryPassword] = useState(createTemporaryPassword());
  const [resetEmail, setResetEmail] = useState('');
  const [deleteEmail, setDeleteEmail] = useState('');
  const currentUserName = useSelector(getUserName);
  const currentUser = managers?.find((manager) => manager.userId === currentUserName);

  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.up('sm'));

  const isEdit = useCallback(
    () => newManagerEmail && newManagerEmail.length > 0,
    [newManagerEmail],
  );

  const handleCloseAddNewManagerDialog = () => {
    setAddManagerDialogOpen(false);
    history.replace('/settings/users');
  };

  const handleClosePwdDialog = () => {
    setConfirmPwdDialogOpen(false);
  };

  const handleCloseManagerDeleteDialog = () => {
    setConfirmManagerDeleteDialogOpen(false);
  };

  const handleEditManager = ({ userId, name, role, countryCode, phone, reportsEmails }) => {
    setNewManagerEmail(userId);
    setNewManagerName(name);
    setNewManagerRole(role);
    setNewManagerCountryCode(countryCode || '+44');
    setNewManagerPhoneNumber(phone || '');
    setAddManagerDialogOpen(true);
    setNewManagerReportsEmails(reportsEmails);
  };

  const submitUpdateManager = async (values) => {
    handleCloseAddNewManagerDialog();
    const { userId, email, role, enabled, name, phone, countryCode, reportsEmails } = values;
    try {
      await dispatch(
        updateManager({
          userId: userId || email,
          role,
          enabled,
          name,
          phone,
          countryCode,
          reportsEmails,
        }),
      );
      showSuccessNotification('Manager updated successfully');
      dispatch(fetchManagers());
    } catch (newError) {
      showErrorNotification('Manager could not be updated');
    }
  };

  const submitAddNewManagerDialog = async ({
    email,
    role,
    name,
    phone,
    countryCode,
    reportsEmails,
  }) => {
    handleCloseAddNewManagerDialog();

    try {
      await dispatch(createManager({ email, role, name, phone, countryCode, reportsEmails }));
      setNewManagerEmail('');
      showSuccessNotification('New manager added successfully');
      dispatch(fetchManagers());
    } catch (newError) {
      showErrorNotification('New manager could not be created');
    }
  };

  const submitPwdDialog = async (email, password) => {
    handleClosePwdDialog();
    try {
      await dispatch(forcePasswordReset({ values: { email, temporaryPassword: password } }));
      showSuccessNotification('Password reset successfully');
      dispatch(fetchAdmin());
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  const submitMangerDeleteDialog = async (managerEmail) => {
    handleCloseManagerDeleteDialog();
    try {
      if (isAdmin()) {
        await dispatch(deleteUser(managerEmail));
      } else {
        await dispatch(deleteManager(managerEmail));
      }
      showSuccessNotification('Manager has been deleted');
      dispatch(fetchVenue());
    } catch (newError) {
      showErrorNotification(getErrorMessage(newError));
    }
  };

  useEffect(() => {
    setEnabledManagers(managers?.filter((manager) => manager.enabled === true));
    setDisabledManager(managers?.filter((manager) => manager.enabled === false));
  }, [managers]);

  useEffect(() => {
    if (confirmPwdDialogOpen || addManagerDialogOpen)
      setTemporaryPassword(createTemporaryPassword());
  }, [confirmPwdDialogOpen, addManagerDialogOpen]);

  useEffect(() => {
    if (!enabledManagers || enabledManagers.length === 0) return;

    if (subtab && addManagerDialogOpen === false) {
      const currentManager = enabledManagers.find(({ userId }) => userId === currentUserName);

      if (currentManager) {
        handleEditManager(currentManager);
        setAddManagerDialogOpen(true);
      }
    }
  }, [addManagerDialogOpen, currentUserName, enabledManagers, subtab]);

  if (!managers) return null;
  return (
    <>
      <header className={classes.heading}>
        <aside>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              handleEditManager({ userId: undefined, name: undefined, role: ROLES.STAFF });
            }}
          >
            Invite
          </Button>
        </aside>
      </header>
      <Typography className={classes.tableTitle}>Enabled users</Typography>
      <Card className={classes.tableCard}>
        <TableContainer component={Paper}>
          <Table size="small">
            <TableHead>
              <TableRow className={classes.tableheader}>
                <TableCell component="td" className={classes.columnTitle}>
                  Name
                </TableCell>
                <TableCell component="td" className={classes.columnTitle}>
                  Role
                </TableCell>
                <TableCell component="td" className={classes.columnTitle}>
                  Email subscriptions
                </TableCell>
                {isAdmin() && (
                  <TableCell component="td" className={classes.columnTitle}>
                    Status
                  </TableCell>
                )}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {enabledManagers?.map((manager) => {
                const cognitoRoles = getRolesFromToken(manager.dashboardIdToken);
                const isUpCoSuper = cognitoRoles.includes(ROLES.SUPERADMIN);
                const isUpCoAdmin = cognitoRoles.includes(ROLES.ADMIN);
                const {
                  userId,
                  role,
                  name = 'No name',
                  countryCode,
                  phone,
                  status,
                  reportsEmails,
                } = manager;
                const noTrailingZeroPhone = phone && phone.replace(/^0+/, '');
                const displayPhone = `${countryCode}${noTrailingZeroPhone}`;
                const displayName = `${name} ${currentUserName === userId ? '(You)' : ''}`;
                const formatReportsEmails = (reports) => {
                  const emailLabels = {
                    DAILY_DELIVERY: 'Daily delivery',
                    WEEKLY_DELIVERY: 'Weekly delivery',
                    DELIVERY_INVOICE: 'Delivery invoice',
                  };

                  const formattedEmails = [];

                  if (reports?.includes('DAILY_DELIVERY') && reports?.includes('WEEKLY_DELIVERY')) {
                    formattedEmails.push('Daily & weekly report');
                  } else {
                    if (reports?.includes('DAILY_DELIVERY'))
                      formattedEmails.push(emailLabels.DAILY_DELIVERY);
                    if (reports?.includes('WEEKLY_DELIVERY'))
                      formattedEmails.push(emailLabels.WEEKLY_DELIVERY);
                  }

                  if (reports?.includes('DELIVERY_INVOICE')) {
                    formattedEmails.push(emailLabels.DELIVERY_INVOICE);
                  }

                  return formattedEmails.join(', ');
                };

                return (
                  <TableRow key={userId} className={classes.tablerow}>
                    <TableCell component="td" scope="row">
                      {displayName.length > 0 && (
                        <div className={`${classes.rowName} ${!isMedium && classes.truncate}`}>
                          <strong>{displayName}</strong>
                          <br />
                        </div>
                      )}
                      <div className={`${classes.userId} ${!isMedium && classes.truncate}`}>
                        {userId}
                      </div>
                      {countryCode && phone && (
                        <div className={`${classes.userId} ${!isMedium && classes.truncate}`}>
                          {displayPhone}
                        </div>
                      )}
                    </TableCell>
                    <TableCell className={classes.role}>
                      {displayRole(isUpCoSuper, isUpCoAdmin, DISPLAYROLES[role])}
                    </TableCell>

                    <TableCell component="td" className={classes.columnTitle}>
                      {formatReportsEmails(reportsEmails)}
                    </TableCell>

                    {isAdmin() && (
                      <TableCell component="td" className={classes.columnTitle}>
                        {status}
                      </TableCell>
                    )}
                    <TableCell align="right">
                      <Box display="flex" justifyContent="flex-end" mr={1}>
                        {(isRoleLessthanMe(role) || currentUserName === userId) && (
                          <KebabMenu
                            items={[
                              {
                                name: `Edit${currentUserName === userId ? ' my' : ''} info`,
                                handler: () => {
                                  handleEditManager(manager);
                                },
                              },
                              ...[
                                currentUserName !== userId && {
                                  name: `Disable`,
                                  handler: () => {
                                    submitUpdateManager({ ...manager, enabled: false });
                                  },
                                },
                                isAdmin() && {
                                  name: `[ADMIN ONLY] Reset password`,
                                  handler: () => {
                                    setResetEmail(userId);
                                    setConfirmPwdDialogOpen(true);
                                  },
                                },
                                isAdmin() && {
                                  name: `[ADMIN ONLY] Permanently delete user`,
                                  handler: () => {
                                    setDeleteEmail(userId);
                                    setConfirmManagerDeleteDialogOpen(true);
                                  },
                                },
                              ],
                            ]}
                          />
                        )}
                      </Box>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Card>
      {disabledManagers.length !== 0 && (
        <>
          <Typography className={classes.tableTitle}>Disabled users</Typography>
          <Card className={classes.tableCard}>
            <TableContainer component={Paper}>
              <Table size="small">
                <TableHead>
                  <TableRow className={classes.tableheader}>
                    <TableCell
                      component="td"
                      className={classes.columnTitle}
                      width={(isMedium && '75%') || null}
                    >
                      Name
                    </TableCell>
                    <TableCell component="td" className={classes.columnTitle}>
                      Role
                    </TableCell>
                    {isAdmin() && (
                      <TableCell component="td" className={classes.columnTitle}>
                        Status
                      </TableCell>
                    )}
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {disabledManagers.map((manager) => {
                    const cognitoRoles = getRolesFromToken(manager.dashboardIdToken);
                    const isUpCoSuper = cognitoRoles.includes(ROLES.SUPERADMIN);
                    const isUpCoAdmin = cognitoRoles.includes(ROLES.ADMIN);
                    const { userId, role, name = 'No name', phone, countryCode, status } = manager;
                    const noTrailingZeroPhone = phone && phone.replace(/^0+/, '');
                    const displayPhone = `${countryCode}${noTrailingZeroPhone}`;
                    const displayName = `${name} ${currentUserName === userId ? '(You)' : ''}`;
                    return (
                      <TableRow key={userId} className={classes.tablerow}>
                        <TableCell component="td" scope="row" width={(isMedium && '80%') || null}>
                          {displayName.length > 0 && (
                            <div className={`${classes.rowName} ${!isMedium && classes.truncate}`}>
                              <strong>{displayName}</strong>
                              <br />
                            </div>
                          )}
                          <div className={`${classes.userId} ${!isMedium && classes.truncate}`}>
                            {userId}
                          </div>
                          {countryCode && phone && (
                            <div className={`${classes.userId} ${!isMedium && classes.truncate}`}>
                              {displayPhone}
                            </div>
                          )}
                        </TableCell>
                        <TableCell className={classes.role} width={(isMedium && '80%') || null}>
                          {displayRole(isUpCoSuper, isUpCoAdmin, DISPLAYROLES[role])}
                        </TableCell>
                        {isAdmin() && (
                          <TableCell component="td" className={classes.columnTitle}>
                            {status}
                          </TableCell>
                        )}
                        <TableCell align="right">
                          <Box display="flex" justifyContent="flex-end" mr={1}>
                            {(isRoleLessthanMe(role) || currentUserName === userId) && (
                              <KebabMenu
                                items={[
                                  {
                                    name: `Edit${currentUserName === userId ? ' my' : ''} info`,
                                    handler: () => {
                                      handleEditManager(manager);
                                    },
                                  },
                                  ...[
                                    currentUserName !== userId && {
                                      name: `Enable`,
                                      handler: () => {
                                        submitUpdateManager({ ...manager, enabled: true });
                                      },
                                    },
                                    isAdmin() && {
                                      name: `[ADMIN ONLY] Reset password`,
                                      handler: () => {
                                        setResetEmail(userId);
                                        setConfirmPwdDialogOpen(true);
                                      },
                                    },
                                    isAdmin() && {
                                      name: `[ADMIN ONLY] Permanently delete user`,
                                      handler: () => {
                                        setDeleteEmail(userId);
                                        setConfirmManagerDeleteDialogOpen(true);
                                      },
                                    },
                                  ],
                                ]}
                              />
                            )}
                          </Box>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Card>
        </>
      )}
      <ManagerFormTabs
        isEdit={isEdit}
        newManagerEmail={newManagerEmail}
        newManagerName={newManagerName}
        newManagerRole={newManagerRole}
        newManagerCountryCode={newManagerCountryCode}
        newManagerPhoneNumber={newManagerPhoneNumber}
        currentUser={currentUser}
        submitUpdateManager={submitUpdateManager}
        submitAddNewManagerDialog={submitAddNewManagerDialog}
        addManagerDialogOpen={addManagerDialogOpen}
        handleCloseAddNewManagerDialog={handleCloseAddNewManagerDialog}
        newManagerReportsEmails={newManagerReportsEmails}
      />
      <ResetPasswordDialog
        handleCloseDialog={handleClosePwdDialog}
        submitDialog={submitPwdDialog}
        handleDialogOpen={confirmPwdDialogOpen}
        email={resetEmail}
        temporaryPassword={temporaryPassword}
      />
      <DeleteVenueManagerDialog
        handleCloseDialog={handleCloseManagerDeleteDialog}
        submitDialog={submitMangerDeleteDialog}
        handleDialogOpen={confirmManagerDeleteDialogOpen}
        managerEmail={deleteEmail || ''}
      />
    </>
  );
};

export default ManagersTableTeam;
