import React from 'react';
import { Route, Switch } from 'react-router';
import Login from '../pages/Login';
import Callback from '../pages/Callback';
import Onboarding from '../pages/Onboarding';
import LayoutNotAuthenticated from '../components/LayoutNotAuthenticated';
import AutoLogin from '../pages/AutoLogin';

const NotAuthenticatedApp = () => (
  <LayoutNotAuthenticated>
    <Switch>
      <Route exact path="/onboarding" component={Onboarding} />
      <Route exact path="/onboarding-fail" component={Onboarding} />
      <Route exact path="/callback" component={Callback} />
      <Route exact path="/autologin" component={AutoLogin} />
      <Route component={Login} />
    </Switch>
  </LayoutNotAuthenticated>
);

export default NotAuthenticatedApp;
