import {
  Button,
  CardActions,
  CardContent,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { ReactComponent as InfoIcon } from '../../../../assets/images/info.svg';
import CustomWarningBanner from '../../../../components/CustomWarningBanner';
import PhoneField from '../../../../components/PhoneField';
import useRoles from '../../../../hooks/useRoles';
import ROLES from '../../../../shared/constants/roles';

const roles = [ROLES.STAFF, ROLES.MANAGER, ROLES.OWNER];
const roleTitle = { [ROLES.STAFF]: 'Member', [ROLES.MANAGER]: 'Admin', [ROLES.OWNER]: 'Owner' };
const roleDscription = {
  [ROLES.STAFF]: 'View order history and tabs',
  [ROLES.MANAGER]: 'View Reports, perform refunds, menu edits, promotions and adjust opening hours',
  [ROLES.OWNER]: 'Full access to all features and settings',
};

const useStyles = makeStyles((theme) => ({
  mainContent: {
    padding: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0,
  },
  bottomContent: {
    padding: theme.spacing(3),
    paddingTop: 0,
  },
  radioGroup: {
    marginTop: theme.spacing(1),
  },
  legend: {
    marginBottom: theme.spacing(1),
  },
  legendTitle: {
    display: 'block',
    paddingTop: theme.spacing(1),
  },
  legendSubtitle: {
    display: 'block',
    color: '#647F99',
  },
  radio: {
    alignItems: 'flex-start',
  },
  gridWrapper: ({ isStaff }) => ({
    overflow: 'auto',
    paddingTop: isStaff() ? '0' : theme.spacing(2),
  }),
  cardActions: {
    borderTop: `1px solid ${theme.customPalette.n2}`,
    padding: theme.spacing(2, 3),
    gap: theme.spacing(1),
  },
  formControl: {
    marginBottom: theme.spacing(1),
  },
}));

const ManagerFormDialog = ({
  action,
  email,
  name,
  role,
  countryCode = '+44',
  phone,
  updateManagerData,
  currentUser,
  handleSubmit,
  handleCloseDialog,
}) => {
  const { isStaff, isRoleAtLeast } = useRoles();
  const classes = useStyles({ isStaff });
  const createMode = action === 'create';
  const buttonTitle = createMode ? 'Send invite' : 'Save';
  const isCurrentUser = currentUser?.userId === email && !createMode;

  const NewManagerSchema = Yup.object().shape({
    email: Yup.string().email('Email must be valid').required('This field is required'),
    role: Yup.string(),
    name: Yup.string().nullable(true),
    phone: Yup.string(),
    countryCode: Yup.string(),
  });

  const EditManagerSchema = Yup.object().shape({
    email: Yup.string().required('This field is required'),
    role: Yup.string(),
    name: Yup.string().nullable(true),
    phone: Yup.string(),
    countryCode: Yup.string(),
  });

  return (
    <Grid className={classes.gridWrapper}>
      <Formik
        initialValues={{ email, role, name, phone, countryCode }}
        validationSchema={createMode ? NewManagerSchema : EditManagerSchema}
        enableReinitialize
        validateOnMount
        onSubmit={handleSubmit}
      >
        {({ errors, touched, handleBlur, handleChange, setFieldValue, isValid, values }) => (
          <Form>
            <CardContent className={classes.mainContent}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <TextField
                    helperText={errors.name && touched.name && errors.name}
                    FormHelperTextProps={{ className: classes.helperText }}
                    name="name"
                    fullWidth
                    size="small"
                    label="Manager Name"
                    variant="outlined"
                    placeholder="Name"
                    margin="normal"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      updateManagerData('name', e.target.value);
                    }}
                    value={values.name}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    helperText={errors.email && touched.email && errors.email}
                    FormHelperTextProps={{ className: classes.helperText }}
                    name="email"
                    fullWidth
                    size="small"
                    label="Manager Email"
                    variant="outlined"
                    placeholder="manager@email.com"
                    required
                    disabled={!createMode && !!email}
                    margin="normal"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      updateManagerData('email', e.target.value);
                    }}
                    value={values.email}
                  />
                </Grid>
                <Grid item xs={12}>
                  <PhoneField
                    isSmall
                    required={false}
                    phoneLabel="Mobile Number"
                    updateManagerData={updateManagerData}
                  />
                </Grid>
              </Grid>
            </CardContent>
            {!isCurrentUser && (
              <CardContent className={classes.bottomContent}>
                <FormControl component="fieldset" className={classes.formControl}>
                  <FormLabel component="legend">
                    <p>
                      <strong>Select their level of access</strong>
                    </p>
                    <span className={classes.legendSubtitle}>
                      How much control do you want them to have?
                    </span>
                  </FormLabel>
                  <RadioGroup
                    name="role"
                    className={classes.radioGroup}
                    onChange={(event) => {
                      setFieldValue('role', event.currentTarget.value);
                      updateManagerData('role', event.currentTarget.value);
                    }}
                  >
                    {roles.map((menurole) => {
                      if (menurole === ROLES.OWNER && !isRoleAtLeast(ROLES.ADMIN)) return null;
                      return (
                        <FormControlLabel
                          className={classes.radio}
                          key={menurole}
                          checked={menurole === values.role}
                          value={menurole}
                          disabled={menurole === ROLES.MANAGER && !isRoleAtLeast(ROLES.OWNER)}
                          control={<Radio color="primary" />}
                          label={
                            <>
                              <span className={classes.legendTitle}>{roleTitle[menurole]}</span>
                              <span className={classes.legendSubtitle}>
                                {roleDscription[menurole]}
                              </span>
                            </>
                          }
                        />
                      );
                    })}
                  </RadioGroup>
                </FormControl>
                {!isRoleAtLeast(ROLES.OWNER) && (
                  <CustomWarningBanner
                    title="Make someone a manager"
                    titleFont="smallBold"
                    titleColor="black"
                    warningFont="small"
                    backgroundColor="#E0E0EB"
                    customIcon={<InfoIcon />}
                  >
                    <span className={classes.legendSubtitle}>
                      Only Owners can upgrade a member to the role of Manager.
                    </span>
                  </CustomWarningBanner>
                )}
                <CustomWarningBanner
                  title="Make someone an owner"
                  titleFont="smallBold"
                  titleColor="black"
                  warningFont="small"
                  backgroundColor="#E0E0EB"
                  customIcon={<InfoIcon />}
                >
                  <span className={classes.legendSubtitle}>
                    Owner status can only be granted or revoked by the Sessions Serve team.
                  </span>
                </CustomWarningBanner>
              </CardContent>
            )}
            <CardActions className={classes.cardActions}>
              <Button variant="outlined" onClick={handleCloseDialog}>
                Cancel
              </Button>
              <Button type="submit" disabled={!isValid} variant="contained" color="primary">
                {buttonTitle}
              </Button>
            </CardActions>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default ManagerFormDialog;
