import React from 'react';

import { Grid, MenuItem, TextField } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Field, useFormikContext } from 'formik';
import { TextField as FormikTextField } from 'formik-mui';
import countryCodes from './countryCodes';

const useStyles = makeStyles(() => ({
  countryCode: {
    minWidth: 150,
  },
}));

/**
 *  Meant to be used within a <Formik /> component
 */

const PhoneField = ({
  isSmall = false,
  phoneLabel = 'Phone',
  required = true,
  updateManagerData,
}) => {
  const classes = useStyles();
  const { handleBlur, handleChange, touched, errors, values } = useFormikContext();
  const defaultValue = countryCodes.find((country) => country.code === 'GB').dialCode;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Field
          component={FormikTextField}
          select
          id="countryCode"
          name="countryCode"
          label="Country code"
          variant="outlined"
          margin="normal"
          require={required}
          fullWidth
          className={classes.countryCode}
          helperText={touched.countryCode && errors.countryCode}
          error={touched.countryCode && Boolean(errors.countryCode)}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
            if (updateManagerData) updateManagerData('countryCode', e.target.value);
          }}
          size={isSmall ? 'small' : 'medium'}
          value={values.countryCode || defaultValue}
        >
          {countryCodes.map(({ code, dialCode, flag, name }) => (
            <MenuItem value={dialCode} key={code}>
              {dialCode} {flag} {name}
            </MenuItem>
          ))}
        </Field>
      </Grid>
      <Grid item xs={12} sm={8}>
        <Field
          component={TextField}
          name="phone"
          id="phone"
          label={phoneLabel}
          type="tel"
          variant="outlined"
          margin="normal"
          required={required}
          fullWidth
          helperText={touched.phone && errors.phone}
          error={touched.phone && Boolean(errors.phone)}
          onBlur={handleBlur}
          onChange={(e) => {
            handleChange(e);
            if (updateManagerData) updateManagerData('phone', e.target.value);
          }}
          value={values.phone}
          size={isSmall ? 'small' : 'medium'}
        />
      </Grid>
    </Grid>
  );
};

export default PhoneField;
