import { getBare } from '../../api/httpRequest';

const handleNavToBigCommerce = async (event) => {
  event.preventDefault();

  const newTab = window.open('', '_blank');

  const resp = await getBare('login/bigcommerce');
  if (resp.status === 200) {
    newTab.location.href = resp.data.responseURL;
  } else {
    newTab.close();
  }
};

export default handleNavToBigCommerce;
