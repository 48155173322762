import { AnalyticsOutlined, ChevronRight } from '@mui/icons-material';
import { Box, Button, Card, CardContent, Grid, Link, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import moment from 'moment';
import React from 'react';
import useRoles from '../../hooks/useRoles';
import handleNavToServeBeta from '../../store/utils/navToServeBeta';

const useStyles = makeStyles((theme) => ({
  cardContent: {
    padding: 0,
    '&:last-child': {
      paddingBottom: 0,
    },
  },
  reportLinkContainer: {
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  reportIconTextContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
}));

const DeliveryReportsCard = () => {
  const classes = useStyles();
  const { isStaff } = useRoles();

  const yesterday = moment().subtract(1, 'days').format('ddd Do');
  const weekly = `Mon - Sun`;

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent className={classes.cardContent}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Button
              onClick={() => handleNavToServeBeta({ path: `/reports/daily` })}
              color="primary"
              underline="none"
              sx={{ width: '100%' }}
            >
              <Box className={classes.reportLinkContainer}>
                <Box className={classes.reportIconTextContainer}>
                  <AnalyticsOutlined fontSize="medium" sx={{ mr: '12px' }} />
                  <Box>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', justifySelf: 'start' }}>
                      Yesterday&apos;s delivery report
                    </Typography>
                    <Typography variant="body1" sx={{ justifySelf: 'start' }}>
                      {yesterday}
                    </Typography>
                  </Box>
                </Box>
                <ChevronRight />
              </Box>
            </Button>
          </Box>
          <Box>
            <Button
              onClick={() => handleNavToServeBeta({ path: `/reports/weekly` })}
              color="primary"
              underline="none"
              sx={{
                width: '100%',
              }}
            >
              <Box className={classes.reportLinkContainer}>
                <Box className={classes.reportIconTextContainer}>
                  <AnalyticsOutlined fontSize="medium" sx={{ mr: '12px' }} />
                  <Box>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', justifySelf: 'start' }}>
                      Weekly delivery report
                    </Typography>
                    <Typography variant="body1" sx={{ justifySelf: 'start' }}>
                      {weekly}
                    </Typography>
                  </Box>
                </Box>
                <ChevronRight />
              </Box>
            </Button>
          </Box>
        </CardContent>
      </Card>
      {!isStaff() && (
        <Box sx={{ pt: 1 }}>
          <Link underline="always" href="/settings/users/notifications">
            Report notifications
          </Link>
        </Box>
      )}
    </Grid>
  );
};

export default DeliveryReportsCard;
