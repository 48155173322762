import React from 'react';
import * as Yup from 'yup';
import { Card, CardContent, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Field, Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { TextField } from 'formik-mui';
import { updateVenue } from '../../../store/venues';
import UniversalSave from '../../../components/UniversalSave';
import withVenue from '../../../hoc/withVenue';
import { getErrorMessage } from '../../../shared/utils/errors';
import { useNotifications } from '../../../shared/contexts/Notifications/useNotifications';
import BackArrow from '../../../components/BackArrow';
import PageHeader from '../../../components/PageHeader';
import Page from '../../../components/Page';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: '8px',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  field: {
    width: '100%',
    margin: 0,
    marginBottom: '10px',
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: theme.customPalette.grey,
      borderRadius: '8px',
    },
    '& .MuiSelect-icon': {
      color: theme.customPalette.greyDark,
    },
    borderRadius: '8px',
  },
  title: {
    ...theme.customFonts.largeBold,
  },
  caption: {
    ...theme.customFonts.small,
    color: theme.customPalette.greyDarker,
    marginBottom: '16px',
  },
  subtitle: {
    ...theme.customFonts.label,
    color: theme.customPalette.greyDarker,
  },
}));

const businessDetailsSchema = Yup.object().shape({
  address: Yup.string().required('Please enter a valid business address'),
  city: Yup.string().required('Please enter a valid city'),
  postcode: Yup.mixed().required('Please enter a valid postcode'),
  vatNumber: Yup.string().optional(),
});

const BusinessDetails = ({ venue, redirect }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showSuccessNotification, showErrorNotification } = useNotifications();
  const {
    address,
    city,
    postcode,
    vatNumber,
    businessName,
    businessAddress,
    businessPostcode,
    businessCity,
    businessRegistrationNumber,
  } = venue || {};
  const initialValues = {
    address,
    city,
    postcode,
    vatNumber,
    businessName,
    businessAddress,
    businessPostcode,
    businessCity,
    businessRegistrationNumber,
  };

  const handleOnSubmit = async (values) => {
    try {
      let transformedValues = values;
      if (values.vatNumber === '') {
        transformedValues = { ...values, vatNumber: 'NONE' };
      }
      await dispatch(updateVenue(transformedValues));
      showSuccessNotification('Successfully updated business details');
    } catch (error) {
      showErrorNotification(getErrorMessage(error));
    }
  };

  return (
    <>
      <PageHeader>
        <BackArrow redirect={redirect} text="Settings" />
        <Typography className={classes.title}>Business details</Typography>
        <Typography className={classes.caption}>
          The following details will be used to generate VAT receipts
        </Typography>
      </PageHeader>
      <Page>
        <Card className={classes.card}>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={businessDetailsSchema}
          >
            {({ values, resetForm, errors, dirty, isValid }) => (
              <>
                <UniversalSave
                  isValid={isValid}
                  dirty={dirty}
                  onSave={() => {
                    handleOnSubmit(values);
                  }}
                  onDiscard={resetForm}
                  errors={errors}
                />
                <Form>
                  <CardContent className={classes.container}>
                    <Typography className={classes.subtitle}>Venue address</Typography>
                    <Field
                      className={classes.field}
                      name="address"
                      component={TextField}
                      variant="outlined"
                      margin="normal"
                      type="text"
                    />
                    <Typography className={classes.subtitle}>Venue City</Typography>
                    <Field
                      className={classes.field}
                      name="city"
                      component={TextField}
                      variant="outlined"
                      margin="normal"
                      type="text"
                    />
                    <Typography className={classes.subtitle}>Venue Postcode</Typography>
                    <Field
                      className={classes.field}
                      name="postcode"
                      component={TextField}
                      variant="outlined"
                    />
                    <Typography className={classes.subtitle}>VAT number</Typography>
                    <Field
                      className={classes.field}
                      name="vatNumber"
                      component={TextField}
                      variant="outlined"
                      margin="normal"
                    />
                    <Typography className={classes.subtitle}>Business Name</Typography>
                    <Field
                      className={classes.field}
                      name="businessName"
                      component={TextField}
                      variant="outlined"
                      margin="normal"
                      type="text"
                    />
                    <Typography className={classes.subtitle}>Business Address</Typography>
                    <Field
                      className={classes.field}
                      name="businessAddress"
                      component={TextField}
                      variant="outlined"
                      margin="normal"
                      type="text"
                    />
                    <Typography className={classes.subtitle}>Business City</Typography>
                    <Field
                      className={classes.field}
                      name="businessCity"
                      component={TextField}
                      variant="outlined"
                      margin="normal"
                      type="text"
                    />
                    <Typography className={classes.subtitle}>Business Postcode</Typography>
                    <Field
                      className={classes.field}
                      name="businessPostcode"
                      component={TextField}
                      variant="outlined"
                      margin="normal"
                      type="text"
                    />
                    <Typography className={classes.subtitle}>
                      Business Registration Number
                    </Typography>
                    <Field
                      className={classes.field}
                      name="businessRegistrationNumber"
                      component={TextField}
                      variant="outlined"
                      margin="normal"
                      type="text"
                    />
                  </CardContent>
                </Form>
              </>
            )}
          </Formik>
        </Card>
      </Page>
    </>
  );
};

export default withVenue(BusinessDetails, '/settings/business');
