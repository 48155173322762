import {
  Button,
  CardActions,
  CardContent,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Form, Formik } from 'formik';
import React from 'react';
import * as Yup from 'yup';
import { ReactComponent as InfoIcon } from '../../../../assets/images/info.svg';
import useRoles from '../../../../hooks/useRoles';

import CustomWarningBanner from '../../../../components/CustomWarningBanner';

const ReportNotificationsSchema = Yup.object().shape({
  dailyReports: Yup.boolean(),
  weeklyReports: Yup.boolean(),
  deliveryInvoice: Yup.boolean(),
});

const useStyles = makeStyles((theme) => ({
  mainContent: ({ isOwner }) => ({
    padding: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: !isOwner() && theme.spacing(1),
  }),

  gridWrapper: {
    overflow: 'auto',
    paddingTop: theme.spacing(2),
  },
  cardActions: {
    borderTop: `1px solid ${theme.customPalette.n2}`,
    padding: theme.spacing(2, 3),
    gap: theme.spacing(1),
  },
  formControl: {
    marginBottom: theme.spacing(1),
  },
}));

const ManagerFormNotifications = ({
  reportsEmails = [],
  updateManagerData,
  handleSubmit,
  handleCloseDialog,
  role,
}) => {
  const { isOwner } = useRoles();
  const classes = useStyles({ isOwner });

  const initialValues = {
    dailyReports: reportsEmails.includes('DAILY_DELIVERY') || false,
    weeklyReports: reportsEmails.includes('WEEKLY_DELIVERY') || false,
    deliveryInvoice: reportsEmails.includes('DELIVERY_INVOICE') || false,
  };

  return (
    <Grid className={classes.gridWrapper}>
      <Formik
        initialValues={initialValues}
        validationSchema={ReportNotificationsSchema}
        enableReinitialize
        validateOnMount
        onSubmit={handleSubmit}
      >
        {({ isValid, values, setFieldValue }) => (
          <Form>
            <CardContent className={classes.mainContent}>
              <FormControl className={classes.formControl}>
                <Typography>Receive the following email notifications.</Typography>

                {[
                  {
                    name: 'dailyReports',
                    label: 'Delivery reports (daily)',
                    emailType: 'DAILY_DELIVERY',
                  },
                  {
                    name: 'weeklyReports',
                    label: 'Delivery reports (weekly)',
                    emailType: 'WEEKLY_DELIVERY',
                  },
                  {
                    name: 'deliveryInvoice',
                    label: 'Delivery invoices',
                    emailType: 'DELIVERY_INVOICE',
                  },
                ].map(({ name, label, emailType }) => {
                  if (role !== 'owner' && name === 'deliveryInvoice') return null;

                  return (
                    <FormControlLabel
                      key={name}
                      control={<Checkbox name={name} color="primary" />}
                      checked={values?.[name]}
                      label={label}
                      onChange={(e) => {
                        const updatedEmails = e.target.checked
                          ? [...reportsEmails, emailType]
                          : reportsEmails.filter((email) => email !== emailType);

                        updateManagerData('reportsEmails', updatedEmails);
                        setFieldValue(name, e.target.checked);
                      }}
                    />
                  );
                })}
              </FormControl>
              {isOwner() && (
                <CustomWarningBanner
                  titleFont="smallBold"
                  titleColor="black"
                  warningFont="small"
                  backgroundColor="#E0E0EB"
                  customIcon={<InfoIcon />}
                >
                  You can also manage these settings for other users.
                </CustomWarningBanner>
              )}
            </CardContent>
            <CardActions className={classes.cardActions}>
              <Button variant="outlined" onClick={handleCloseDialog}>
                Cancel
              </Button>
              <Button type="submit" disabled={!isValid} variant="contained" color="primary">
                Save
              </Button>
            </CardActions>
          </Form>
        )}
      </Formik>
    </Grid>
  );
};

export default ManagerFormNotifications;
