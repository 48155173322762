import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import CustomDialog from '../../../../components/CustomDialog';
import TabContainer from '../../../../components/TabContainer';
import ManagerFormDialog from './ManagerFormAccount';
import ManagerFormNotifications from './ManagerFormNotifications';

const ManagerFormTabs = ({
  isEdit,
  newManagerEmail,
  newManagerName,
  newManagerRole,
  newManagerCountryCode,
  newManagerPhoneNumber,
  currentUser,
  submitUpdateManager,
  submitAddNewManagerDialog,
  addManagerDialogOpen = false,
  handleCloseAddNewManagerDialog,
  newManagerReportsEmails,
}) => {
  const { subtab } = useParams();
  const action = isEdit() ? 'update' : 'create';
  const createMode = action === 'create';
  const title = createMode ? 'New user' : 'Edit info';

  const initialManagerData = useMemo(
    () => ({
      email: createMode ? '' : newManagerEmail || '',
      name: createMode ? '' : newManagerName || '',
      role: newManagerRole || '',
      countryCode: newManagerCountryCode || '',
      phone: newManagerPhoneNumber || '',
      reportsEmails: newManagerReportsEmails || [],
    }),
    [
      createMode,
      newManagerEmail,
      newManagerName,
      newManagerRole,
      newManagerCountryCode,
      newManagerPhoneNumber,
      newManagerReportsEmails,
    ],
  );

  const [managerData, setManagerData] = useState(initialManagerData);

  useEffect(() => {
    setManagerData(initialManagerData);
  }, [initialManagerData]);

  const updateManagerData = (field, value) => {
    setManagerData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = () => {
    const submitAction = isEdit() ? submitUpdateManager : submitAddNewManagerDialog;
    submitAction(managerData);
  };

  const managerFormProps = {
    action,
    email: managerData.email,
    name: managerData.name,
    role: managerData.role,
    countryCode: managerData.countryCode,
    phone: managerData.phone,
    updateManagerData,
    currentUser,
    handleSubmit,
    handleCloseDialog: handleCloseAddNewManagerDialog,
  };

  return (
    <CustomDialog
      title={title}
      isDialogOpen={addManagerDialogOpen}
      handleCloseDialog={handleCloseAddNewManagerDialog}
    >
      {managerData.role === 'staff' ? (
        <ManagerFormDialog {...managerFormProps} />
      ) : (
        <TabContainer
          withinModal
          titles={['Account', 'Notifications']}
          defaultTab={subtab}
          path="/settings/users/"
          dialogopen={addManagerDialogOpen}
        >
          <ManagerFormDialog {...managerFormProps} />
          <ManagerFormNotifications
            reportsEmails={managerData.reportsEmails}
            updateManagerData={updateManagerData}
            handleSubmit={handleSubmit}
            handleCloseDialog={handleCloseAddNewManagerDialog}
            role={managerData.role}
          />
        </TabContainer>
      )}
    </CustomDialog>
  );
};

export default ManagerFormTabs;
