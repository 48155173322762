import { TabContext, TabPanel } from '@mui/lab';
import { Slide, Tabs, useTheme } from '@mui/material';
import { makeStyles } from '@mui/styles';
import React, { Children, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import ToolTab from './ToolTab';

const useStyles = makeStyles((theme) => ({
  tabs: ({ withinModal }) => ({
    margin: withinModal ? theme.spacing(0, 2) : '0',
    marginBottom: withinModal ? '0' : theme.spacing(4),
    [theme.breakpoints.up('sm')]: {
      marginBottom: withinModal ? '0' : theme.spacing(2),
    },
    boxShadow: `0px -2px 0px 0px ${theme.palette.divider} inset`,
  }),
  tabPanel: {
    padding: 0,
  },
}));

function capitalizeFirstLetter(string) {
  if (!string) return string;
  const withSpaces = string.replace('-', ' ');
  return withSpaces.charAt(0).toUpperCase() + withSpaces.slice(1);
}

const TabContainer = ({
  titles,
  children,
  onChange,
  defaultTab = titles[0],
  path,
  withinModal = false,
  subtab,
  pageTab,
}) => {
  const classes = useStyles({ withinModal });
  const theme = useTheme();
  const history = useHistory();
  const arrayChildren = Children.toArray(children);
  const largeMediaQuery = `@media (minWidth:${theme.breakpoints.values.lg}px)`;
  const startTabValue = defaultTab ? capitalizeFirstLetter(defaultTab) : titles[0];
  const [tabValue, setTabValue] = useState(startTabValue);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
    if (path) history.replace(`${path}${newValue.replace(' ', '-').toLowerCase()}`);
    if (onChange) onChange(newValue);
  };

  useEffect(() => {
    if (defaultTab === '' && path && pageTab && subtab) {
      setTabValue(capitalizeFirstLetter(pageTab));
      history.replace(`${path}${pageTab}/${subtab}`);
    } else {
      setTabValue(capitalizeFirstLetter(defaultTab));
      if (path) history.replace(`${path}${defaultTab.replace(' ', '-').toLowerCase()}`);
    }
  }, [defaultTab, history, pageTab, path, subtab, withinModal]);

  return (
    <TabContext value={tabValue} className={classes.tabContext}>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="toolbox"
        className={classes.tabs}
        variant="scrollable"
        scrollButtons={false}
        TabIndicatorProps={{
          style: {
            background: '#333333',
            [largeMediaQuery]: {
              left: 0,
            },
          },
        }}
      >
        {titles.map((title) => (
          <ToolTab key={title} label={title} value={title} className={classes.tab} />
        ))}
      </Tabs>

      {Children.map(arrayChildren, (child, index) => (
        <Slide key={titles[index]} direction="up" in={tabValue === titles[index]}>
          <TabPanel value={titles[index]} className={classes.tabPanel}>
            {React.cloneElement(arrayChildren[index], { titles, setTabValue })}
          </TabPanel>
        </Slide>
      ))}
    </TabContext>
  );
};

export default TabContainer;
