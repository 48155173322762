import React from 'react';
import { useSelector } from 'react-redux';
import { makeStyles } from '@mui/styles';
import { Card, CardContent, Typography, Link as MuiLink, Grid } from '@mui/material';
import {
  ContactSupportOutlined,
  LightbulbOutlined,
  VisibilityOffOutlined,
  UTurnLeftOutlined,
  StorefrontOutlined,
} from '@mui/icons-material';
import { useIntercom } from 'react-use-intercom';
import Grocery from '../../assets/images/grocery.svg';
import handleNavToBigCommerce from '../../store/utils/navToBigCommerce';

import { isDeliveryOnly, isFranchise, isPro } from '../../store/venues/selectors';

const useStyles = makeStyles(() => ({
  title: {
    fontFamily: 'Sen',
    fontWeight: 'bold',
    flexGrow: 1,
    marginBottom: '1rem',
  },
  link: {
    display: 'flex',
    gap: '16px',
    '&:visited': {
      color: '#333333',
    },
  },
}));

const QuickActionsCard = () => {
  const isFranchiseHost = useSelector(isFranchise);
  const isProHost = useSelector(isPro);
  const isDeliveryHost = useSelector(isDeliveryOnly);
  const classes = useStyles();
  const { show } = useIntercom();

  return (
    <Grid item xs={12}>
      <Card>
        <CardContent>
          <Typography className={classes.title} variant="h4">
            Quick actions
          </Typography>
          <p>
            <MuiLink href="#" className={classes.link} onClick={handleNavToBigCommerce}>
              <StorefrontOutlined />
              Open the Sessions Store
            </MuiLink>
          </p>
          {(isFranchiseHost || isDeliveryHost) && (
            <>
              <p>
                <MuiLink
                  href="https://www.bidfood.co.uk/login/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className={classes.link}
                >
                  <img src={Grocery} alt="Grocery icon" style={{ paddingLeft: '4px' }} />
                  Order from Bidfood
                </MuiLink>
              </p>
            </>
          )}
          {isProHost && (
            <>
              <p>
                <MuiLink href="/orders-history" className={classes.link}>
                  <UTurnLeftOutlined sx={{ transform: 'rotate(90deg)' }} />
                  Refund an order
                </MuiLink>
              </p>
            </>
          )}
          <p>
            <MuiLink href="/items" className={classes.link}>
              <VisibilityOffOutlined />
              Mark an item as out of stock
            </MuiLink>
          </p>
          <p>
            <MuiLink
              href="https://hub.sessions.co.uk/knowledge/general-faqs"
              target="_blank"
              rel="noopener noreferrer"
              className={classes.link}
            >
              <LightbulbOutlined />
              FAQ&apos;s
            </MuiLink>
          </p>
          <p>
            <MuiLink underline="always" href="#" onClick={show} className={classes.link}>
              <ContactSupportOutlined />
              Need help? Message us!
            </MuiLink>
          </p>
        </CardContent>
      </Card>
    </Grid>
  );
};

export default QuickActionsCard;
