import { del, get, patch, post } from './httpRequest';

let loadingLog = false;

export const logManager = async (managerId, session) => {
  if (loadingLog) return null;
  loadingLog = true;
  const response = await patch(`/managers/${managerId}/log`, {
    data: session,
  });
  loadingLog = false;
  return response.data;
};

export const invalidateManager = async (managerId) => {
  await post(`/managers/${managerId}/invalidate`, {
    data: {},
  });
};

export const createManager = async ({ email, role, name, phone, countryCode, reportsEmails }) => {
  await post(`/managers`, {
    data: { email, role, name, phone, countryCode, reportsEmails },
  });
};
export const updateManager = async ({
  userId,
  enabled,
  role,
  name,
  phone,
  countryCode,
  reportsEmails,
}) => {
  await patch(`/managers/${userId}`, {
    data: { enabled, role, name, phone, countryCode, reportsEmails },
  });
};

export const fetchManagers = async () => {
  const response = await get(`/managers`);
  const { data } = response;
  return data;
};

export const deleteManager = async (userId) => {
  await del(`/managers/${userId}`);
};
